<template>
  <MDBCard class="m-3" :class="{ 'd-none':!Model }">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <MDBRow>
      <MDBCol md="12">
        <div class="card-box">
          <div class="panel-body">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table m-t-30">
                    <thead class="bg-faded">
                      <tr>
                        <th class="text-center">{{ $t("COMMON.PRODUCTNAME")}}</th>
                        <th class="text-center">{{ $t("COMMON.VERSION")}}</th>
                        <th class="text-center">{{ $t("COMMON.FREE")}}</th>
                        <th class="text-center">{{ $t("COMMON.SUBSCRIPTIONPLAN")}}</th>
                        <th class="text-center">{{ $t("COMMON.QUANTITY")}}</th>
                        <th class="text-center">{{ $t("COMMON.PRICE")}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="typeof(data.commodity) != 'undefined'">
                        <td class="text-center">{{ data.product.name }}</td>
                        <td class="text-center">{{ (typeof data.commodity.commodity.version == 'object')?data.commodity.commodity.version[lang].name:data.commodity.commodity.version }}</td>
                        <td class="text-center">{{ (data.commodity.commodity.free)?$t("COMMON.YES"):$t("COMMON.NO") }}</td>
                        <td class="text-center">{{ $t(`COMMON.MONTH${data.commodity.category.month}`) }}</td>
                        <td class="text-center">{{ data.count }}</td>
                        <td class="text-center">{{ data.commodity.amount }}/{{ data.commodity.currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row" v-if="data.promosable_id">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table m-t-30">
                    <thead class="bg-faded">
                      <tr>
                        <th class="text-center">優惠代碼名稱</th>
                        <th class="text-center">優惠碼編號</th>
                        <th class="text-center">抵扣金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">{{ ((data.promosable_id=='dealer_percentage')?"經銷商優惠":data.promo.name) }}</td>
                        <td class="text-center">{{ ((data.promosable_id=='dealer_percentage')?"":data.promo.code) }}</td>
                        <td class="text-center">{{ data.promo_amount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="clearfix m-t-30">
                  <div class="float-right m-t-30">
                    <p><strong>{{ $t("COMMON.ORDERTIME") }}: </strong> {{ Common.TimeFormat(data.created_at) }}</p>
                    <p>
                      <strong>{{ $t("COMMON.ORDERPSTATUS") }}: </strong>
                      <span class="badge  text-wrap" :class="{ 'bg-danger': (data.status == 0),'bg-success': (data.status == 1),'bg-warning': (data.status == 5) }">
                        {{ $t("ORDER.STATUS_" + data.status) }}
                      </span>
                    </p>
                    <p class="m-t-10"><strong>{{ $t("COMMON.ORDERNUMBER") }}: </strong> {{ data.order }}</p>
                    <p class="m-t-10" v-if="data.third_order"><strong>綠界訂單號: </strong> {{ data.third_order }}</p>
                    <p class="m-t-10"><strong>付款類型: </strong> {{ $t(`COMMON.${data.type}`) }}</p>
                    <p class="m-t-10"><strong>產品類型: </strong> {{ $t(`PRODUCTTYPE.TYPE_${data.product_type}`) }}</p>
                    <p class="m-t-10"><strong>{{ $t("COMMON.MEMBERNAME") }}: </strong> {{ data.name }}</p>
                    <p class="m-t-10"><strong>{{ $t("COMMON.EMAIL") }}: </strong> {{ data.email }}</p>
                    <p class="m-t-10"><strong>自動續約: </strong> {{ (data.auto)?$t("COMMON.YES"):$t("COMMON.NO") }}</p>
                    <p class="m-t-10"><strong>抬頭: </strong> {{ data.title }}</p>
                    <p class="m-t-10"><strong>統一編號: </strong> {{ data.invoice }}</p>
                    <p class="m-t-10"><strong>帳單地址: </strong> {{ data.address }}</p>
                    <template v-if="data.payment_able > 0">
                      <p class="m-t-10"><strong>{{ $t("COMMON.SUBSCRIPTIONAMOUNT") }}: </strong> {{ data.order_amount }}</p>
                      <p class="m-t-10"><strong>{{ $t("COMMON.SUBSCRIPTIONNUMBER") }}: </strong> {{ data.order_count }}</p>
                      <p class="m-t-10"><strong>訂閱起訖日: </strong> <template v-if="data.payment_at">
                        {{ Common.TimeFormat(data.payment_at) }} ~ {{ (data.subscription_end_time)?Common.TimeFormat(data.subscription_end_time):"" }}
                      </template></p>
                      <p class="m-t-10"><strong>{{ $t("COMMON.PAYMENTEDNUMBER") }}: </strong> {{ data.payment_count }}</p>
                      <p class="m-t-10"><strong>{{ $t("COMMON.CUMULATIVEPAYMENTAMOUNT") }}: </strong> {{ (['bank','atm'].indexOf(data.type)>=0)?data.really_amount:(data.payment_count * data.really_amount) }}</p>
                      <p class="m-t-10"><strong>{{ $t("COMMON.NEXTPAYMENTDATE") }}: </strong> {{ ((!!data.payment_at)?NextDate(data.payment_at,((['bank','atm'].indexOf(data.type)>=0?data.payment_able:1))):"") }}</p>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-6 ">
                <p class="text-right"><b>{{ $t("COMMON.ORDERCOUNT") }}:</b> {{ data.amount }} x {{ data.count }}</p>
                <p class="text-right">{{ $t("COMMON.ORDERPROMO") }}: {{ data.promo_amount }}</p>
                <hr>
                <h3 class="text-right">{{ data.currency }} {{ data.order_really_total }}</h3>
                <div v-if="data.type=='bank'" class="form">
                  <p class="text-right"><strong>付款資料:</strong></p>
                  <p class="text-right" v-if="data.status==5 || data.status==1">
                    <img :src="data.file[0].url" class="img-thumbnail" v-if="data.file.length>0">
                  </p>
                  <MDBBtn type="button" @click="Confirm" :disabled="SubmitLoading" color="success" v-if="data.status==5">
                    確認金額入帳
                    <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
                      <span class="visually-hidden">Loading...</span>
                    </span>
                  </MDBBtn>
                </div>
              </div>
            </div>
            <hr>
            <div class="hidden-print">
              <div class="float-right">
                <!-- <a href="javascript:window.print()" class="btn btn-dark waves-effect waves-light"><i class="fa fa-print"></i></a> -->
                <MDBBtn type="button" @click="Close">{{ $t("COMMON.CLOSE") }}</MDBBtn>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .badge-info {
    color: #ffffff;
    background-color: #8950FC;
  }
  .badge-warning {
    color: #181C32;
    background-color: #FFA800;
  }
  .messageReplyContent * {
    white-space: normal;
    word-break: break-word;
  }
</style>

<script>
import { MDBContainer,MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "OrderDetail",
  components: {
		MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn,
    MDBCardBody,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    },
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });
    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });
    const SubmitLoading = ref(false);
    const Common = CommonService;
    const EditUUid = ref("");
    const data = ref({});
    const GetTable = inject('GetTable');
    const lang = ref(i18nService.getActiveLanguage());

    const NextDate = function(date,month) {
      let d = new Date(date);
      d.setMonth(d.getMonth()+month);
      return d.toLocaleString();
    }

    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
    };

    const Confirm = function() {
      CommonService.ConfirmSwal().then(result => {
        if (result.isConfirmed) {
          SubmitLoading.value = true;
          ApiService.update("/Admin/v1/Order" ,props.uuid).then(response => {
            SubmitLoading.value = false;
            if (response.status == 200) {
              GetDetail();
              GetTable();
              CommonService.AxiosHandle(response);
            }
          });
        }
      });
    }

    const GetDetail = function() {
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Order", FormUUid.value).then(response => {
            if (response.status == 200) {
              data.value = response.data.data;
              Model.value = true;
              resolve(response);
            }
          });
        })
      ]);
    };

		return {
      Model,
      SubmitLoading,
      FormUUid,
      Common,
      Close,
      EditUUid,
      data,
      NextDate,
      Confirm,
      GetDetail,
      lang
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();
      }
    }
  },
};
</script>